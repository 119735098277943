<template>
  <q-page class="detailPage">
    <div class="container">
      <div class="row items-start">
        <template v-if="loading || Object.keys(selectedProduct).length">
          <div class="col-12 productBack" v-if="!isMobile">
            <q-skeleton type="rect" width="100px" v-if="loading" />
            <a
              href="javascript:void(0)"
              @click="applyBack()"
              class="no-underline"
              v-else
            >
              <SvgIcon class="q-mr-sm" size=".8rem" icon="back-arrow-line" />
              Back
            </a>
          </div>
          <div class="col-12 col-lg-9 col-md-8 productLeft">
            <!-- Product Detail Component -->
            <ProductDetail
              :productDetail="selectedProduct"
              :isRoutingEnabled="true"
              :isInputIsProduct="true"
              :loading="loading"
            />

            <!-- Product Tabs Begin -->
            <ProductDetailTabs
              :shortDescription="selectedProduct.shortDescription || ''"
              :attributes="selectedProduct.attributes || []"
              :loading="loading"
              :productID="selectedProduct.productID"
              :seoName="selectedProduct.seoName"
              :productDetail="selectedProduct"
            />
            <!-- Product Tabs End -->

            <!-- Frequently Bought Begin -->
            <BoughtTogether
              v-if="
                businessConfigurations.enableCompanion &&
                selectedProduct.isinstock
              "
              :seoName="seoName"
              :fulFillments="selectedProduct.fulFillments || []"
            />
            <!-- Frequently Bought End -->
          </div>

          <div class="col-12 col-lg-3 col-md-4 productRight">
            <!-- <LastOrderedInfo
              v-if="isLoggedIn && !isMobile && selectedProduct?.lastPurchased"
              :lastPurchasedDetail="selectedProduct"
            /> -->
            <!-- Last Ordered Begin -->
            <LastOrderedInfo
              v-if="isLoggedIn && selectedProduct?.lastPurchased"
              :lastPurchasedDetail="selectedProduct"
            />
            <!-- Last Ordered End -->
            <!-- Product Offer Begin -->
            <ProductOfferInfo
              v-if="selectedProduct?.discounts?.length"
              :discountsDetail="selectedProduct"
            />
            <!-- Product Offer End -->
            <!-- Similar Product Begin -->
            <SimilarProducts
              v-if="businessConfigurations.enableSubstitution"
              :seoName="seoName"
            />
            <!-- Similar Product End -->
          </div>
        </template>
        <div class="col-12" v-else>
          <EmptyComponent
            image="images/empty-product.png"
            btnText="Continue Shopping"
            to="/"
          >
            <p>
              Currently, this product is not available for the selected
              location. Please select another location.
            </p>
          </EmptyComponent>
        </div>
      </div>
    </div>

    <!-- Write Review Dialog Begin -->
    <WriteReview />
    <!-- Write Review Dialog End -->
  </q-page>
</template>

<script>
import ProductDetail from 'components/detail/ProductDetail'
import ProductDetailTabs from 'components/detail/ProductDetailTabs'
import BoughtTogether from 'components/detail/BoughtTogether'
import SimilarProducts from 'components/detail/SimilarProducts'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'PageDetail',
  components: {
    ProductDetail,
    ProductDetailTabs,
    BoughtTogether,
    SimilarProducts,
    LastOrderedInfo: () => import('components/detail/LastOrderedInfo'),
    ProductOfferInfo: () => import('components/detail/ProductOfferInfo'),
    WriteReview: () => import('components/detail/WriteReview'),
  },
  props: ['seoName'],
  preFetch({ store, currentRoute, redirect }) {
    let selectedLocationId = store.getters['persisted/currentLocationId'],
      { seoName } = currentRoute.params,
      currentDeliveryModes = store.getters['common/currentDeliveryModes'],
      promises = []

    promises.push(
      store
        .dispatch('product/getProductDetail', {
          ProductSeoName: seoName,
          locationID: selectedLocationId,
          fulfillments: store.getters['persisted/selectedFulfillmentCodeName'],
          zipCode: store.getters['persisted/locationOrShippingZip'],
        })
        .then((data) => {
          if (!data) store.commit('product/SET_LOADING', false)
          // redirect({
          //   name: 'Home',
          // })
        })
    )
    /* promises.push(
      store.dispatch('product/getCompanionProducts', {
        locationId: selectedLocationId,
        ...currentDeliveryModes,
        ProductSeoName: seoName,
        fulfillments: store.getters['persisted/selectedFulfillmentCodeName'],
        zipCode: store.getters['persisted/locationOrShippingZip'],
      })
    ) */

    if (process.env.SERVER) return Promise.all(promises)
  },
  meta() {
    return {
      title: this.title,
      meta: {
        title: {
          name: 'title',
          content: this.ogMeta.title,
        },
        description: {
          name: 'description',
          content: this.ogMeta.description,
        },
        ogTitle: {
          property: 'og:title',
          content: this.ogMeta.title,
        },
        ogDescription: {
          property: 'og:description',
          content: this.ogMeta.description,
        },
        ogImage: {
          property: 'og:image',
          content: this.ogMeta.imageUri,
        },
        ogUrl: {
          property: 'og:url',
          content: this.currentRoutePath,
        },
      },
      link: {
        canonical: {
          rel: 'canonical',
          href: this.currentRoutePath,
        },
      },
    }
  },
  data() {
    return {
      tab: 'description',
      rating_point: 4.3,
      val: true,
      sort: 'Featured',
      sortOptions: ['Featured', 'New', 'Best Seller'],
    }
  },
  computed: {
    ...mapState(['product']),
    ...mapGetters('product', ['loading', 'getProductBySeoName']),
    ...mapGetters('common', ['currentDeliveryModes']),
    title() {
      return this.selectedProduct ? this.selectedProduct.title || '' : ''
    },
    selectedProduct() {
      return this.getProductBySeoName(this.seoName)
    },
    productId() {
      return this.product.seoMapping[this.seoName]
    },
    ogMeta() {
      let metaData = {
        title: ``,
        description: '',
        imageUri: '',
      }

      if (this.title) metaData.title = this.title
      if (this.selectedProduct?.shortDescription)
        metaData.description = this.selectedProduct.shortDescription
      if (this.selectedProduct?.images?.length)
        metaData.imageUri =
          this.selectedProduct.images[0].similarImages[0].imageURL

      return metaData
    },
  },
  mounted() {
    // prefetch code
    let selectedLocationId = this.$store.getters['persisted/currentLocationId'],
      currentDeliveryModes = this.$store.getters['common/currentDeliveryModes'],
      { seoName } = this.$route.params

    this.$store.dispatch('product/getCompanionProducts', {
      locationId: selectedLocationId,
      ...currentDeliveryModes,
      ProductSeoName: seoName,
      fulfillments:
        this.$store.getters['persisted/selectedFulfillmentCodeName'],
    })
    // prefetch code
  },
}
</script>
<style lang="scss">
.detailPage {
  @media (min-width: 1024px) {
    padding: 20px 0;
  }
  @media (max-width: 1023px) {
    padding-top: 24px;
    padding-bottom: 60px;
  }
  .container {
    max-width: 1440px;
  }
  .productBack,
  .productLeft,
  .productRight {
    padding: 0;
    @media (min-width: 1024px) {
      padding: 0 40px;
    }
  }
  .productBack {
    margin: 0 0 15px;
    a {
      color: #888888;
      font-size: 13px;
    }
    @media (min-width: 1681px) {
      margin: 15px 0;
      a {
        font-size: 16px;
      }
    }
  }
  @media (min-width: 1024px) {
    .productRight {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 1px;
        height: 100%;
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }
  @media (max-width: 1023px) {
    .productRight {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }
  @media (max-width: 1379px) {
    .productLeft {
      padding: 0 15px;
    }
    .productRight {
      padding: 0 15px;
      .similarProduct {
        .text-caption {
          width: 100%;
          padding-right: 0;
        }
        .btn-wishlist,
        .wishlist-btn {
          top: -4px;
          bottom: auto;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .productLeft {
      width: 100%;
    }
    .productRight {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &::before {
        content: none;
      }
      .lastOrderedInfo {
        width: 48%;
        .q-card__section {
          height: 100%;
          display: flex;
          align-items: stretch;
          width: 100%;
          flex-wrap: wrap;
          .row.items-center {
            width: 100%;
            .text-right {
              text-align: left;
            }
          }
        }
      }
      .productOfferInfo {
        width: 48%;
      }
      .productOfferInfo + div {
        width: 100%;
      }
      .similarProduct {
        .text-caption {
          align-self: flex-start;
          width: auto;
          padding-right: 60px;
        }
        .btn-wishlist,
        .wishlist-btn {
          top: auto;
          bottom: 4px;
          margin-bottom: 0;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .productLeft,
    .productRight {
      padding: 0;
    }
  }
  @media (max-width: 600px) {
    .productRight {
      .lastOrderedInfo {
        width: 100%;
      }
      .productOfferInfo {
        width: 100%;
      }
    }
  }
  @media (max-width: 374px) {
    .productRight {
      .similarProduct {
        .btn-wishlist,
        .wishlist-btn {
          padding-left: 0;
          top: -4px;
          bottom: auto;
        }
      }
    }
  }
}
.pinCart {
  @media (min-width: 1024px) {
    .productLeft {
      width: 100%;
    }
    .productRight {
      width: 100%;
      &:before {
        content: none;
      }
    }
  }
  @media (min-width: 1681px) {
    .productLeft {
      width: 75%;
    }
    .productRight {
      width: 25%;
      &:before {
        content: '';
      }
    }
  }
  .similarProduct {
    .q-item__section--avatar {
      min-width: 120px;
    }
  }
}
.offerBlock {
  @media (max-width: 1024px) {
    max-height: 95px;
    overflow-y: scroll;
    padding-right: 10px;
  }
}
</style>
