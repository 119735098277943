<template>
  <div class="row q-col-gutter-md" v-if="loading">
    <div class="col-xs-12 col-sm-12 col-md-12 q-mt-md">
      <div class="row q-col-gutter-md">
        <div class="col-4 col-md-2"
          ><q-skeleton type="rect" width="100%" height="40px" class="q-mb-sm" />
        </div>
        <div class="col-4 col-md-2"
          ><q-skeleton type="rect" width="100%" height="40px" class="q-mb-sm" />
        </div>
        <div class="col-4 col-md-2"
          ><q-skeleton type="rect" width="100%" height="40px" class="q-mb-sm" />
        </div>
        <div class="col-12">
          <q-skeleton type="text" width="100%" class="q-mb-sm" />
          <q-skeleton type="text" width="100%" class="q-mb-sm" />
          <q-skeleton type="text" width="40%" class="q-mb-md" />
          <q-skeleton type="text" width="100%" class="q-mb-sm" />
          <q-skeleton type="text" width="100%" class="q-mb-sm" />
          <q-skeleton type="text" width="40%" class="q-mb-md" />
        </div>
      </div>
    </div>
  </div>
  <div
    v-else-if="!loading || shortDescription || (attributes && attributes.length)"
    class="product-tabs"
  >
    <q-card flat>
      <q-tabs
        v-model="tab"
        class="text-grey"
        active-color="secondaryOnBody"
        indicator-color="secondaryOnBody"
        align="left"
      >
        <q-tab name="description" label="Description" v-if="shortDescription" />
        <q-tab
          v-for="(attribute, index) in attributes"
          :key="index"
          :name="attribute.title"
          :label="attribute.title"
        />
        <q-tab
          v-if="businessConfigurations.enableReviews"
          name="review"
          label="Customer Reviews"
        />
      </q-tabs>

      <q-separator />

      <q-tab-panels v-model="tab" animated swipeable>
        <q-tab-panel name="description" v-if="shortDescription">
          <div class="ql-editor" data-gramm="false" contenteditable="false">
            <p>{{ shortDescription }}</p>
          </div>
        </q-tab-panel>

        <q-tab-panel
          v-for="(attribute, index) in attributes"
          :key="index"
          :name="attribute.title"
        >
          <div class="row">
            <div class="col-12">
              <q-list bordered class="rounded-borders" style="">
                <q-item
                  v-for="count in Math.ceil(
                    attribute.productAttribute.length / 2
                  )"
                  :key="count"
                >
                  <q-item-section
                    v-for="i in 2"
                    :key="2 * (count - 1) + (i - 1)"
                    class="col-6"
                  >
                    <template
                      v-if="
                        attribute.productAttribute[2 * (count - 1) + (i - 1)]
                      "
                    >
                      <span class="text-caption text-grey">{{
                        attribute.productAttribute[2 * (count - 1) + (i - 1)]
                          .displayName
                      }}</span>
                      <span>{{
                        attribute.productAttribute[2 * (count - 1) + (i - 1)]
                          .attributeValue
                      }}</span>
                    </template>
                  </q-item-section>
                </q-item>
              </q-list>
            </div>
          </div>
        </q-tab-panel>

        <q-tab-panel class="tab-review" name="review">
          <Review
            :seoName="seoName"
            v-if="businessConfigurations.enableReviews && seoName"
            :productDetail="productDetail"
          />
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
  </div>
</template>
<script>
import Review from 'components/detail/Review'
export default {
  name: 'ProductDetailTabs',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    attributes: {
      type: Array,
      default: () => {
        new Array()
      },
    },
    shortDescription: {
      type: String,
      default: '',
    },
    productID: {
      type: String,
      default: '',
    },
    seoName: {
      type: String,
      default: '',
    },
    productDetail: {
      type: Object,
      default: () => {
        return new Object({})
      },
    },
  },
  components: {
    Review,
  },
  data() {
    return {
      model: '',
      tab: 'description',
    }
  },
  watch: {
    attributes(newVal, oldVal) {
      if (newVal && newVal != oldVal) this.markDefaultActiveTab()
    },
  },
  created() {
    this.markDefaultActiveTab()
  },
  methods: {
    markDefaultActiveTab() {
      if (!this.shortDescription && this.attributes && this.attributes.length)
        this.tab = this.attributes[0].title
      else this.tab = 'description'

      if (
        !this.shortDescription &&
        (!this.attributes ||
          (this.attributes &&
            this.attributes.length == 0 &&
            this.businessConfigurations.enableReviews))
      ) {
        this.tab = 'review'
      }
    },
  },
}
</script>
<style lang="scss">
/* Product Tabs */
.product-tabs {
  padding: 20px 0;
  .q-tab {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
    text-transform: none;
    &.q-tab--active {
      color: #000 !important;
      .q-tab__label {
        font-weight: bold !important;
      }
    }
    .q-tab__indicator {
      height: 4px;
    }
    @media (min-width: 1024px) {
      padding: 0 40px;
    }
  }
  .q-tab-panel {
    padding: 20px 0;
    p {
      font-size: 16px;
      line-height: 24px;
      color: #666666;
    }
    &.tab-review {
      padding: 0;
      .tab-review-info,
      .tab-review-listing {
        padding: 20px 0 0;
      }
      .tab-review-listing {
        .custom-scroll {
          max-height: 400px;
        }
      }
      @media (min-width: 768px) {
        .tab-review-info {
          padding: 30px 50px;
          padding-left: 0;
          border-right: 1px solid rgba(112, 112, 112, 0.2);
        }
        .tab-review-listing {
          padding: 30px 50px;
          padding-right: 0;
        }
      }
      .tab-review-info {
        @media (max-width: 767px) {
          border-bottom: 1px solid rgba(112, 112, 112, 0.2);
        }
        .total-percent {
          font-size: 48px;
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          color: #000000;
        }
        .total-count {
          font-size: 16px;
          color: #888888;
        }
        .q-list {
          .q-item {
            min-height: 24px;
            span {
              font-size: 12px;
              color: #888888;
            }
            .q-linear-progress {
              border-radius: 5px;
              .q-linear-progress__model {
                border-radius: 5px;
                background: #ffbd09;
              }
            }
          }
        }
      }
      .tab-review-toolbar {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        font-size: 16px;
        color: #888888;
        @media (min-width: 768px) {
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: center;
        }
        .tab-review-count,
        .tab-review-sorting {
          padding: 10px 0;
        }
        .tab-review-sorting {
          display: flex;
          align-items: center;
        }
      }
      .review-detail-list {
        padding-right: 12px;
        word-break: break-all;
        .q-item {
          padding: 15px 0px;
          .review-detail-name {
            font-size: 20px;
            color: #314154;
            padding-bottom: 10px;
          }
          .review-detail-ratings {
            font-size: 16px;
            color: #314154;
            padding-bottom: 10px;
          }
          .review-detail-date {
            font-size: 14px;
            color: #888888;
            padding-bottom: 10px;
          }
          .review-detail-comment {
            p {
              font-size: 14px;
              line-height: 20px;
              color: #888888;
              padding-bottom: 10px;
              word-break: break-word;
            }
          }
          .review-detail-action {
            a {
              color: #888888;
              &:hover {
                color: $secondary;
                color: var(--q-color-secondaryOnBody) !important;
              }
            }
          }
        }
      }
    }
  }
}
/* Product Tabs */
</style>
