import { render, staticRenderFns } from "./ProductDetailTabs.vue?vue&type=template&id=2b89eb8e"
import script from "./ProductDetailTabs.vue?vue&type=script&lang=js"
export * from "./ProductDetailTabs.vue?vue&type=script&lang=js"
import style0 from "./ProductDetailTabs.vue?vue&type=style&index=0&id=2b89eb8e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QColor from 'quasar/src/components/color/QColor.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QSkeleton,QCard,QTabs,QTab,QSeparator,QTabPanels,QTabPanel,QList,QItem,QItemSection,QLinearProgress,QColor});
