<template>
  <div class="row">
    <div class="col-12 col-sm-5 col-md-4 tab-review-info">
      <template>
        <q-item>
          <q-item-label class="total-percent q-mr-md">
            <template v-if="summary">
              {{ summary.overAllRatings }}
            </template>
            <q-skeleton
              v-show="loadingSummary"
              type="rect"
              width="55px"
              height="50px"
            />
          </q-item-label>
          <q-item-label>
            <q-rating
              v-if="summary"
              v-model="summary.overAllRatings"
              max="5"
              size="1.2em"
              color="orange"
              icon="star_border"
              icon-selected="star"
              icon-half="star_half"
              no-dimming
              readonly
            />
            <div
              v-if="summary"
              class="total-count text-subtitle2"
              v-html="`${summary.totalReviews} ratings`"
            />

            <!-- loader -->
            <q-icon
              name="star_border"
              color="grey-4"
              class="q-mr-xs"
              size="19px"
              v-show="loadingSummary"
            />
            <q-icon
              name="star_border"
              color="grey-4"
              class="q-mr-xs"
              size="19px"
              v-show="loadingSummary"
            />
            <q-icon
              name="star_border"
              color="grey-4"
              class="q-mr-xs"
              size="19px"
              v-show="loadingSummary"
            />
            <q-icon
              name="star_border"
              color="grey-4"
              class="q-mr-xs"
              size="19px"
              v-show="loadingSummary"
            />
            <q-icon
              name="star_border"
              color="grey-4"
              class="q-mr-xs"
              size="19px"
              v-show="loadingSummary"
            />
            <q-skeleton
              type="rect"
              width="80px"
              height="15px"
              class="q-mt-sm"
              v-show="loadingSummary"
            />
            <!-- loader -->
          </q-item-label>
        </q-item>
        <q-list dense bordered padding class="no-border">
          <template v-if="summary">
            <q-item
              v-for="(rating, index) of summary.ratings"
              :key="`${rating.name}-${index}`"
            >
              <span class="col-4 q-mr-xs">
                {{ rating.name }} star ({{ rating.total }})
              </span>
              <q-linear-progress
                class="q-ml-sm q-mr-sm"
                style="margin-top: 5px;"
                size="md"
                :value="rating.percentage / 100"
              />
            </q-item>
          </template>
          <template v-if="loadingSummary">
            <q-item v-for="no in 5" :key="no">
              <span class="col-4">
                <q-skeleton type="text" width="50px" />
              </span>
              <span class="col-8">
                <q-skeleton type="text" width="100%" />
              </span>
            </q-item>
          </template>
        </q-list>
        <q-item class="column">
          <q-btn
            unelevated
            rounded
            no-caps
            color="primary"
            v-if="summary && summary.allowWriteReview"
            label="Write a review"
            padding="sm"
            class="full-width q-ma-sm"
            @click="customerReviewDialog"
          />
          <q-skeleton v-show="loadingSummary" type="QBtn" width="100%" />
          <q-btn
            unelevated
            rounded
            outline
            no-caps
            color="dark"
            label="See all reviews"
            padding="sm"
            class="full-width q-ma-sm"
            v-if="false"
          />
          <!-- <q-skeleton
            v-show="loadingSummary && false"
            type="QBtn"
            width="100%"
          /> -->
        </q-item>
      </template>
      <template v-if="summary && summary.ownReview">
        <q-list class="review-detail-list">
          <q-btn
            unelevated
            no-caps
            outline
            class="text-caption float-right no-hover"
            label="Edit a review"
            padding="5px 12px"
            @click="customerReviewDialog"
          />
          <p class="text-h6">My Review</p>

          <q-item>
            <q-item-section>
              <q-item-label
                class="review-detail-name"
                v-text="summary.ownReview.reviewTitle"
              />

              <q-item-label class="review-detail-ratings">
                <q-rating
                  :value="summary.ownReview.ratings"
                  max="5"
                  size="1.2em"
                  color="orange"
                  icon="star_border"
                  icon-selected="star"
                  icon-half="star_half"
                  no-dimming
                  readonly
                />
                <!-- <span
                  class="text-subtitle2 text-weight-bold q-ml-sm"
                  v-if="false"
                >
                  (357 reviews)
                </span> -->
              </q-item-label>
              <q-item-label class="review-detail-date">
                {{ summary.ownReview.reviewDate | formatDate }}
              </q-item-label>
              <q-item-label class="review-detail-comment">
                <p v-html="summary.ownReview.reviewDescription" />
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </template>
    </div>
    <div class="col-12 col-sm-7 col-md-8 tab-review-listing">
      <div class="tab-review-toolbar" v-show="reviews.length">
        <div class="tab-review-count">
          Showing 1-{{ reviews.length }} of {{ totalRatings }} reviews
        </div>
        <div class="tab-review-sorting">
          <span class="q-mr-sm">Sort:</span>
          <q-select
            dense
            borderless
            v-model="sort"
            :options="sortOptions"
            transition-show="jump-up"
            transition-hide="jump-up"
            map-options
            emit-value
            @input="refreshListing"
          />
        </div>
      </div>
      <!-- <q-scroll-area
        :thumb-style="{ ...scrollAreaThumbStyle, zIndex: 2000 }"
        :bar-style="{ ...scrollAreaBarStyle, zIndex: 2000 }"
        :visible="scrollAreaVisible"
        :style="{
          height: `400px`,
        }"
      > -->
      <div class="custom-scroll scroll-style-1">
        <q-infinite-scroll
          @load="getReview"
          :offset="1500"
          class="product-items"
          ref="infiniteScroll"
        >
          <q-list class="review-detail-list">
            <q-item v-for="(review, index) in reviews" :key="index">
              <q-item-section>
                <q-item-label class="review-detail-name">
                  {{ review.customerName }}
                </q-item-label>
                <q-item-label class="review-detail-ratings">
                  <q-rating
                    :value="review.reviewRating"
                    max="5"
                    size="1.2em"
                    color="orange"
                    icon="star_border"
                    icon-selected="star"
                    icon-half="star_half"
                    no-dimming
                    readonly
                  />

                  <span
                    class="text-subtitle2 text-weight-bold q-ml-sm"
                    v-if="false"
                    >(357 reviews)</span
                  >
                </q-item-label>
                <q-item-label class="review-detail-date">
                  {{ review.reviewDate | formatDate }}
                </q-item-label>
                <q-item-label class="review-detail-image" v-if="false">
                  <img
                    src="http://placehold.it/60"
                    class="q-mr-sm q-mb-sm"
                    width="60"
                  /><img
                    src="http://placehold.it/60"
                    class="q-mr-sm q-mb-sm"
                    width="60"
                  />
                </q-item-label>
                <q-item-label class="review-detail-comment">
                  <p>
                    {{ review.reviewDescription }}
                  </p>
                </q-item-label>
                <q-item-label class="review-detail-action">
                  <div class="row">
                    <div class="col-auto q-pr-md">
                      <q-btn
                        unelevated
                        @click="customerFeedback(index, 0)"
                        :class="[
                          'no-underline no-hover',
                          review.isLiked ? 'active' : '',
                        ]"
                        padding="sm"
                      >
                        <SvgIcon
                          :color="review.isLiked ? 'primary' : ''"
                          :icon="review.isLiked ? 'thumb-likef' : 'thumb-like'"
                          size="1rem"
                        />
                      </q-btn>
                      {{ review.likes }}
                    </div>
                    <div class="col-auto q-pr-md">
                      <q-btn
                        unelevated
                        @click="customerFeedback(index, 1)"
                        :class="[
                          'no-underline no-hover',
                          review.isDisLiked ? 'active' : '',
                        ]"
                        padding="sm"
                      >
                        <SvgIcon
                          :color="review.isDisLiked ? 'primary' : ''"
                          :icon="
                            review.isDisLiked
                              ? 'thumb-dislikef'
                              : 'thumb-dislike'
                          "
                          size="1rem"
                        />
                      </q-btn>
                      {{ review.dislikes }}
                    </div>
                    <div class="col text-right">
                      <q-btn
                        unelevated
                        no-caps
                        class="text-caption text-underline float-right no-hover"
                        @click="customerFeedback(index, 2)"
                        :label="
                          review.isReportedAbuse ? 'Undo Report' : 'Report this'
                        "
                        v-if="!review.isSelfReview"
                        padding="sm"
                      />
                    </div>
                  </div>
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
          <template v-slot:loading>
            <ReviewSkeleton v-show="skeletonLoading" />
            <LoadMoreSpinner v-show="!skeletonLoading" />
          </template>
        </q-infinite-scroll>
        <EmptyComponent
          v-if="!loading && reviews.length == 0"
          image="images/empty-review.png"
        >
          <p>No Review available</p>
        </EmptyComponent>
      </div>
      <!-- </q-scroll-area> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ReviewSkeleton from './ReviewSkeleton'

export default {
  props: {
    seoName: {
      type: String,
      default: '',
    },
    productDetail: {
      type: Object,
      default: () => {
        return new Object({})
      },
    },
  },
  data() {
    return {
      loadingSummary: false,
      loading: false,
      skeletonLoading: false,
      summary: null,
      PageSize: 5,
      totalRatings: null,
      reviews: [],
      sort: '-ReviewDate',
      reviewDetail: {},
      sortOptions: [
        { label: 'Recent', value: '-ReviewDate' },
        { label: 'Rating', value: '-ReviewRating' },
        { label: 'Most Liked', value: '-Likes' },
        { label: 'Most Disliked', value: '-Dislikes' },
      ],
    }
  },
  components: { ReviewSkeleton },
  computed: {
    getReviewRequest() {
      return {
        ProductSeoName: this.seoName,
        PageSize: this.PageSize,
        Sorts: this.sort,
      }
    },
  },
  methods: {
    customerReviewDialog() {
      if (this.summary.ownReview) this.reviewDetail = this.summary.ownReview

      this.changeDialogState({
        dialog: 'writeReview',
        properties: {
          customerReview: { ...this.reviewDetail, editReview: true },
        },
        refresh: false,
        val: true,
      })
    },
    getSummary() {
      this.loadingSummary = true

      this.$store
        .dispatch('product/getReviewsSummary', {
          ProductSeoName: this.seoName,
        })
        .then((response) => {
          if (response) {
            this.summary = Object.assign({}, response)
          }
        })
        .finally(() => {
          this.loadingSummary = false
        })
    },
    getReview(index, done) {
      let params = {
        Page: index,
        ...this.getReviewRequest,
      }

      this.loading = true

      if (index == 1) {
        this.reviews = this.reviews.splice(0, 0)
        this.skeletonLoading = true
      }

      this.$store
        .dispatch('product/getReviewsByProduct', params)
        .then((response) => {
          if (response && response.items && response.items.length) {
            this.reviews = [...this.reviews, ...response.items].splice(0)
            this.totalRatings = response.count
          }
          done(!response.hasNext)
        })
        .catch((error) => {
          done(true)
        })
        .finally(() => {
          this.skeletonLoading = false
          this.loading = false
        })
    },
    customerFeedback(index, feedback) {
      let applyCustomerFeedback = () => {
        let params = {
          reviewID: this.reviews[index].reviewID,
          response: feedback,
        }

        this.$store
          .dispatch('customerReview/customerFeedbackOnReview', params)
          .then((response) => {
            if (response && response.data) {
              this.reviews[index] = Object.assign({}, response.data)
              this.reviews = this.reviews.splice(0)
            }
          })
      }

      if (!this.isLoggedIn)
        return this.changeDialogState({
          dialog: 'accountDrawer',
          val: !this.getDialogState('accountDrawer'),
        })

      if (!this.customerExists)
        return this.signUpConfirm().onOk(() => {
          applyCustomerFeedback(index, feedback)
        })

      applyCustomerFeedback(index, feedback)
    },
    refreshListing() {
      if (this.$refs && this.$refs.infiniteScroll) {
        this.$refs.infiniteScroll.stop()
        this.$refs.infiniteScroll.reset()
        this.$refs.infiniteScroll.resume()
        this.$refs.infiniteScroll.trigger()
      }
    },
    getProductReview() {
      let image = ''
      if (
        this.productDetail.images &&
        this.productDetail.images.length > 0 &&
        this.productDetail.images[0].similarImages.length > 0
      ) {
        image = this.productDetail.images[0].similarImages.find(
          (element) => element.imageType === 'Thumbnail'
        )
      }
      this.reviewDetail = {
        metaDescription: this.productDetail.metaDescription,
        metaKeywords: this.productDetail.metaKeywords,
        metaTitle: this.productDetail.metaTitle,
        productID: this.productDetail.productID,
        productName: this.productDetail.title,
        profileImage: image && image.imageURL ? image.imageURL : '',
        reviewID: this.productDetail.reviewID,
        seoName: this.productDetail.seoName,
      }
    },
  },
  created() {
    this.getSummary()
    this.getProductReview()

    this.$root.$on('review-added', (data) => {
      if (!data) {
        this.getSummary()
        this.refreshListing()
      }
    })

    this.$root.$on('editedReviewResponse', (data) => {
      if (data && data.reviewID) {
        this.summary.ownReview = data
        this.getSummary()
        this.refreshListing()
      }
    })
  },
}
</script>
