<template>
  <div
    class="frequentlyBought"
    v-if="companionProducts && Object.keys(companionProducts).length > 1"
  >
    <h4>Frequently Bought Together</h4>
    <div class="frequentlyBoughtBox">
      <div class="frequentlyProduct">
        <div class="frequentlyBoughtImg">
          <q-list>
            <q-item v-for="productId in selectedProducts" :key="productId">
              <box-image
                :link="{
                  name: 'Detail',
                  params: { seoName: companionProducts[productId].seoName },
                }"
                :imageSrc="companionProducts[productId].profileImage"
              />
            </q-item>
          </q-list>
        </div>
        <div class="frequentlyBoughtPrice">
          <span class="price">
            {{ totalPrice | currency }} <span>Total price</span>
          </span>
        </div>
        <div class="frequentlyBoughtAction">
          <AddAllToCartBtn
            :companionProducts="companionProducts"
            :productFulFillments="getAvailablefulfillments"
            :selectedProducts="selectedProducts"
          />
          <AddToFavorite
            :products="companionProducts"
            :selectedProducts="selectedProducts"
            buttonType="text"
            class="no-hover"
          />

          <AddToWishlist
            :products="companionProducts"
            :selectedProducts="selectedProducts"
            label="Add all to wishlist"
            className="full-width q-mt-sm"
          />
        </div>
      </div>
      <q-list class="frequentlyBoughtList" v-if="companionProducts">
        <q-item v-for="product in companionProducts" :key="product.productID">
          <q-item-section avatar="">
            <q-checkbox
              v-model="selectedProducts"
              :disable="
                selectedProducts.length == 1 &&
                selectedProducts.includes(product.productID)
              "
              :val="product.productID"
            />
          </q-item-section>
          <q-item-section>
            <router-link
              class="no-underline"
              :to="{
                name: 'Detail',
                params: { seoName: product.seoName },
              }"
              >{{ product.title }}</router-link
            >
          </q-item-section>
          <q-item-section side>
            <AddToCartBtn
              :productFulFillments="
                product.fulFillments
                  ? product.fulFillments
                  : product.productFulfillments
              "
              :productId="product.productID"
              :isinstock="product.isinstock"
            />
            <AddToFavorite :products="product" />
            <AddToWishlist :products="product" />
          </q-item-section>
        </q-item>
      </q-list>
    </div>
  </div>
</template>
<script>
import AddToCartBtn from '../AddToCartBtn'
import AddAllToCartBtn from '../AddAllToCartBtn'
import AddToFavorite from '../AddToFavorite'
import AddToWishlist from '../AddToWishlist.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'BoughtTogether',
  components: { AddToCartBtn, AddToFavorite, AddAllToCartBtn, AddToWishlist },
  props: {
    fulFillments: {
      type: Array,
      default: () => {
        return new Array()
      },
    },
    seoName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      cartDelivery: '',
      checkedProducts: [],
      blnCheckedProducsSet: false,
    }
  },
  computed: {
    companionProducts() {
      if (this.seoName) {
        return this.getCompanionProductsById(this.seoName)
      }
      return null
    },
    totalPrice() {
      let totalPrice = 0

      for (let productId of this.selectedProducts) {
        totalPrice += this.companionProducts[productId].price
      }

      return totalPrice
    },
    selectedProducts: {
      get() {
        if (this.blnCheckedProducsSet) {
          return this.checkedProducts
        }
        // return this.val
        let products = []

        if (!this.companionProducts) {
          return products
        }

        for (let productId in this.companionProducts) {
          products.push(productId)
        }

        return products
      },
      set(value) {
        this.blnCheckedProducsSet = true
        this.checkedProducts = value
      },
    },
    ...mapGetters('product', ['getCompanionProductsById']),
    ...mapGetters('cart', ['cartHasInStore', 'cartHasCurbside']),
    getAvailablefulfillments() {
      var availableFulfillments = Object.values(this.fulFillments).filter(
        (fulfillment) => fulfillment.available == true
      )
      if (this.cartHasCurbside) {
        return availableFulfillments.filter(
          (fulfillment) => fulfillment.codeName !== 'is'
        )
      } else if (this.cartHasInStore) {
        return availableFulfillments.filter(
          (fulfillment) => fulfillment.codeName !== 'cs'
        )
      }
      return availableFulfillments
    },
  },
}
</script>
<style lang="scss">
/* Frequently Bought Box */
.frequentlyBought {
  padding: 20px 0;
  h4 {
    margin: 0 0 10px;
    padding: 0;
    font-size: 18px;
    font-weight: bold;
    color: #333b3e;
  }
  @media (max-width: 599px) {
    padding-top: 0;
    padding-bottom: 30px;
  }
}
.frequentlyBoughtBox {
  margin: 0;
  padding: 20px;
  border: 1px dashed $primary;
  .frequentlyProduct {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    .frequentlyBoughtImg {
      padding: 0;
      .q-list {
        padding: 0 0 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        .q-item {
          padding: 0;
          margin-right: 20px;
          position: relative;
          a {
            min-width: 70px;
            width: 100%;
            padding: 5px;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
          }
          &:before {
            content: '+';
            font-size: 19px;
            position: absolute;
            top: 50%;
            left: -15px;
            transform: translateY(-50%);
          }
          &:first-child:before {
            content: none;
          }
        }
      }
    }
    .frequentlyBoughtPrice {
      padding-right: 20px;
      .price {
        font-size: 24px;
        font-weight: bold;
        color: $primary;
        color: var(--q-color-primary) !important;
        span {
          padding-top: 8px;
          display: block;
          font-size: 14px;
          font-weight: normal;
          color: #333b3e;
        }
      }
      @media (max-width: 599px) {
        width: 100%;
        padding-bottom: 15px;
        padding-right: 0px;
      }
    }
    .frequentlyBoughtAction {
      width: 180px;
      @media (max-width: 599px) {
        width: 100%;
      }
    }
  }
  .frequentlyBoughtList {
    padding: 30px 0 0;
    & > .q-item {
      padding: 0;
      align-items: center;
      .q-item__section--avatar {
        padding-right: 5px;
        min-width: 46px;
      }
      .q-item__section--main {
        font-size: 14px;
        color: #333b3e;
        a {
          color: #333b3e;
          &:hover {
            color: $primary;
            color: var(--q-color-primary) !important;
          }
        }
        & + .q-item__section--side {
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          min-width: 200px;
        }
      }
      .product-cart {
        width: 200px;
        margin: 10px 0;
      }
      .btn-wishlist {
        display: inline-block;
        padding: 5px 15px;
        .icbg {
          fill: #fff;
        }
        .icborder {
          fill: #314154;
        }
        &.selected {
          .icbg {
            fill: #ec7a7a;
          }
          .icborder {
            fill: #ec7a7a;
          }
        }
      }
      @media (max-width: 767px) {
        flex-wrap: wrap;
        .q-item__section--main {
          & + .q-item__section--side {
            padding-top: 8px;
            padding-bottom: 16px;
            flex: 1 0 100%;
            padding-left: 46px;
            justify-content: flex-start;
          }
        }
      }
    }
  }
}
/* Frequently Bought Box */
</style>
