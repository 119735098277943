var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"similarProduct"},[(_vm.loading && _vm.substituteProducts.length == 0)?_c('q-skeleton',{attrs:{"type":"text","width":"140px","height":"30px"}}):_vm._e(),(_vm.substituteProducts && _vm.substituteProducts.length)?[_c('h5',[_vm._v("Similar Products")]),_c('a',{staticClass:"swiper-button-prev",class:[
          'swiper-button-prev',
          !_vm.hasPrevious || _vm.loading ? 'swiper-button-disabled' : '',
        ],attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.fetchSubstituteProducts(_vm.currentPage - 1)}}}),_c('a',{class:[
          'swiper-button-next',
          !_vm.hasNext || _vm.loading ? 'swiper-button-disabled' : '',
        ],attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.fetchSubstituteProducts(_vm.currentPage + 1)}}})]:_vm._e(),(_vm.loading)?[_c('q-list',_vm._l((3),function(n){return _c('q-item',{key:n,staticClass:"item-start"},[_c('q-item-section',{attrs:{"avatar":"","top":""}},[_c('q-skeleton',{attrs:{"width":"60px","height":"60px"}})],1),_c('q-item-section',[_c('q-skeleton',{attrs:{"type":"text","width":"100%"}}),_c('q-skeleton',{staticClass:"q-mb-sm",attrs:{"type":"text","width":"40%"}}),_c('q-skeleton',{staticClass:"q-mb-md",attrs:{"type":"rect","width":"50%","height":"20px"}}),_c('q-skeleton',{staticClass:"q-mb-sm",attrs:{"type":"QChip","width":"140px","height":"40px"}})],1)],1)}),1)]:[_c('q-list',_vm._l((_vm.substituteProducts),function(product,index){return _c('SimilarProductCard',{key:`${product.productID}-${index}`,attrs:{"product":product}})}),1)]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }