import { render, staticRenderFns } from "./AddAllToCartBtn.vue?vue&type=template&id=36b80af2"
import script from "./AddAllToCartBtn.vue?vue&type=script&lang=js"
export * from "./AddAllToCartBtn.vue?vue&type=script&lang=js"
import style0 from "./AddAllToCartBtn.vue?vue&type=style&index=0&id=36b80af2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QColor from 'quasar/src/components/color/QColor.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QInnerLoading,QSpinner,QBtn,QColor,QItem,QRadio,QField});
