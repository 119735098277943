<template>
  <div>
    <div class="similarProduct">
      <q-skeleton
        v-if="loading && substituteProducts.length == 0"
        type="text"
        width="140px"
        height="30px"
      />

      <template v-if="substituteProducts && substituteProducts.length">
        <h5>Similar Products</h5>
        <a
          href="javascript:void(0);"
          @click="fetchSubstituteProducts(currentPage - 1)"
          class="swiper-button-prev"
          :class="[
            'swiper-button-prev',
            !hasPrevious || loading ? 'swiper-button-disabled' : '',
          ]"
        />
        <a
          href="javascript:void(0);"
          @click="fetchSubstituteProducts(currentPage + 1)"
          :class="[
            'swiper-button-next',
            !hasNext || loading ? 'swiper-button-disabled' : '',
          ]"
        />
      </template>

      <template v-if="loading">
        <q-list>
          <q-item v-for="n in 3" :key="n" class="item-start">
            <q-item-section avatar top>
              <q-skeleton width="60px" height="60px" />
            </q-item-section>
            <q-item-section>
              <q-skeleton type="text" width="100%" />
              <q-skeleton type="text" width="40%" class="q-mb-sm" />
              <q-skeleton
                type="rect"
                width="50%"
                height="20px"
                class="q-mb-md"
              />
              <q-skeleton
                type="QChip"
                width="140px"
                height="40px"
                class="q-mb-sm"
              />
            </q-item-section>
          </q-item>
        </q-list>
      </template>
      <template v-else>
        <q-list>
          <SimilarProductCard
            v-for="(product, index) in substituteProducts"
            :key="`${product.productID}-${index}`"
            :product="product"
          />
        </q-list>
      </template>
    </div>
  </div>
</template>
<script>
import SimilarProductCard from 'components/SimilarProductCard'
import { mapGetters } from 'vuex'

function initialState() {
  return {
    cartDelivery: '',
    val: true,
    loading: false,
    substituteProducts: [],
    pageSize: 3,
    currentPage: 1,
    hasPrevious: false,
    hasNext: false,
  }
}

export default {
  name: 'SimilarProducts',
  components: {
    SimilarProductCard,
  },
  props: ['seoName'],
  data: function () {
    return initialState()
  },
  computed: {
    ...mapGetters('common', ['currentDeliveryModes']),
  },
  methods: {
    objRequest() {
      return {
        locationId: this.currentLocationId,
        PageSize: this.pageSize,
        Page: this.currentPage,
        ProductSeoName: this.seoName,
        fulfillments: this.selectedFulfillmentCodeName,
        ...this.currentDeliveryModes,
      }
    },
    async fetchSubstituteProducts(pageNumber) {
      this.currentPage = pageNumber > 0 ? pageNumber : 1
      let params = this.objRequest()

      this.loading = true

      this.$store
        .dispatch('product/getSubstituteProducts', {
          params,
        })
        .then((response) => {
          if (response) {
            this.hasNext = response.hasNext
            this.hasPrevious = response.hasPrevious
            if (response.items && response.items.length) {
              this.substituteProducts = response.items
            } else {
              this.currentPage =
                this.currentPage > 1 ? this.currentPage - 1 : this.currentPage
            }
          }
          this.loading = false
        })
    },
  },
  created() {
    this.fetchSubstituteProducts(this.currentPage)
  },
}
</script>
<style lang="scss">
.similarProduct {
  position: relative;
  h5 {
    margin: 0;
    padding: 0 0 0;
    font-size: 18px;
    font-weight: bold;
    color: #333b3e;
    @media (min-width: 1681px) {
      font-size: 24px;
    }
  }
  .q-card {
    margin: 15px 0;
    & > .q-item {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .q-item__section--avatar {
    min-width: 30%;
    justify-content: flex-start;
    @media (max-width: 1379px) {
      min-width: 106px;
    }
  }
  a {
    color: #333b3e;
    &:hover {
      // color: $secondary;
      // color: $primaryText;
      color: var(--q-color-secondaryOnBody) !important;
    }
    &.swiper-button-prev,
    &.swiper-button-next {
      &:hover {
        color: $primaryText !important;
      }
    }
  }
  .product-title {
    margin-bottom: 5px;
    font-size: 16px;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .product-qty {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #333b3e;
    display: block;
    padding-right: 60px;
    @media (max-width: 1379px) {
      padding-right: 0px;
    }
    @media (max-width: 374px) {
      padding-right: 40px;
    }
    span {
      display: block;
      font-size: 11px;
      font-weight: normal;
    }
  }
  .product-compare {
    margin-bottom: 15px;
  }

  .product-cart {
    max-width: 210px;
  }

  .btn-wishlist {
    font-size: 13px;
    margin-bottom: 10px;
    position: absolute;
    right: 2px;
    top: -4px;
    .icon {
      top: -9px;
      left: -9px;
      right: auto;
    }
    @media (max-width: 1379px) {
      // padding-left: 30px;
      top: auto;
      bottom: 4px;
      margin-bottom: 0;
    }
    @media (max-width: 374px) {
      padding-left: 0;
      top: -4px;
      bottom: auto;
    }
  }
  .wishlist-btn {
    font-size: 13px;
    // margin-bottom: 10px;
    position: absolute;
    right: 30px;
    top: 0px;
    .icon {
      top: -9px;
      left: -9px;
      right: auto;
    }
    @media (max-width: 1379px) {
      // padding-left: 30px;
      top: auto;
      bottom: 4px;
      margin-bottom: 0;
    }
    @media (max-width: 374px) {
      padding-left: 0;
      top: -4px;
      bottom: auto;
    }
  }
  .q-item__section--main {
    @media (min-width: 1380px) {
      .q-item__section--side {
        padding-right: 0;
      }
    }
    @media (max-width: 374px) {
      .q-item__section--side {
        padding-right: 0;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 30px;
    height: 30px;
    background: $secondary;
    background: var(--q-color-secondary) !important;
    color: #fff;
    border-radius: 50%;
    margin-top: 0;
    top: 1px;
    right: 0;
    text-decoration: none;
    &:after {
      content: '';
      width: 8px;
      height: 8px;
      display: block;
      border-right: 1px solid #fff;
      border-top: 1px solid #fff;
      border-right-color: var(--q-color-secondaryText) !important;
      border-top-color: var(--q-color-secondaryText) !important;
      transform: rotate(45deg);
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -4px;
      margin-left: -6px;
    }
  }
  .swiper-button-prev {
    left: auto;
    right: 40px;
  }
  .swiper-button-prev:after {
    transform: rotate(-135deg);
    margin-left: -3px;
  }
  .text-caption {
    position: relative;
    @media (max-width: 1379px) {
      align-self: flex-start;
      padding-right: 60px;
    }
    @media (max-width: 374px) {
      align-self: stretch;
      padding-right: 0px;
    }
  }
}
</style>
