import { render, staticRenderFns } from "./Review.vue?vue&type=template&id=6f2b3632"
import script from "./Review.vue?vue&type=script&lang=js"
export * from "./Review.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QItem from 'quasar/src/components/item/QItem.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QRating from 'quasar/src/components/rating/QRating.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QList from 'quasar/src/components/item/QList.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QItem,QItemLabel,QSkeleton,QRating,QIcon,QList,QLinearProgress,QBtn,QItemSection,QSelect,QScrollArea,QInfiniteScroll});
