var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.companionProducts && Object.keys(_vm.companionProducts).length > 1)?_c('div',{staticClass:"frequentlyBought"},[_c('h4',[_vm._v("Frequently Bought Together")]),_c('div',{staticClass:"frequentlyBoughtBox"},[_c('div',{staticClass:"frequentlyProduct"},[_c('div',{staticClass:"frequentlyBoughtImg"},[_c('q-list',_vm._l((_vm.selectedProducts),function(productId){return _c('q-item',{key:productId},[_c('box-image',{attrs:{"link":{
                name: 'Detail',
                params: { seoName: _vm.companionProducts[productId].seoName },
              },"imageSrc":_vm.companionProducts[productId].profileImage}})],1)}),1)],1),_c('div',{staticClass:"frequentlyBoughtPrice"},[_c('span',{staticClass:"price"},[_vm._v("\n          "+_vm._s(_vm._f("currency")(_vm.totalPrice))+" "),_c('span',[_vm._v("Total price")])])]),_c('div',{staticClass:"frequentlyBoughtAction"},[_c('AddAllToCartBtn',{attrs:{"companionProducts":_vm.companionProducts,"productFulFillments":_vm.getAvailablefulfillments,"selectedProducts":_vm.selectedProducts}}),_c('AddToFavorite',{staticClass:"no-hover",attrs:{"products":_vm.companionProducts,"selectedProducts":_vm.selectedProducts,"buttonType":"text"}}),_c('AddToWishlist',{attrs:{"products":_vm.companionProducts,"selectedProducts":_vm.selectedProducts,"label":"Add all to wishlist","className":"full-width q-mt-sm"}})],1)]),(_vm.companionProducts)?_c('q-list',{staticClass:"frequentlyBoughtList"},_vm._l((_vm.companionProducts),function(product){return _c('q-item',{key:product.productID},[_c('q-item-section',{attrs:{"avatar":""}},[_c('q-checkbox',{attrs:{"disable":_vm.selectedProducts.length == 1 &&
              _vm.selectedProducts.includes(product.productID),"val":product.productID},model:{value:(_vm.selectedProducts),callback:function ($$v) {_vm.selectedProducts=$$v},expression:"selectedProducts"}})],1),_c('q-item-section',[_c('router-link',{staticClass:"no-underline",attrs:{"to":{
              name: 'Detail',
              params: { seoName: product.seoName },
            }}},[_vm._v(_vm._s(product.title))])],1),_c('q-item-section',{attrs:{"side":""}},[_c('AddToCartBtn',{attrs:{"productFulFillments":product.fulFillments
                ? product.fulFillments
                : product.productFulfillments,"productId":product.productID,"isinstock":product.isinstock}}),_c('AddToFavorite',{attrs:{"products":product}}),_c('AddToWishlist',{attrs:{"products":product}})],1)],1)}),1):_vm._e()],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }