<template>
  <q-card flat>
    <q-item class="item-start">
      <q-item-section avatar>
        <q-img
          spinner-color="primary"
          :src="product.profileImage | defaultProductImage"
          :ratio="1"
        />
      </q-item-section>

      <q-item-section>
        <router-link
          class="no-underline"
          :to="{
            name: 'Detail',
            params: { seoName: product.seoName },
          }"
        >
          <q-item-label class="product-title ellipsis-2-lines">
            {{ product.title }}
            <q-tooltip
              v-if="!isMobile"
              max-width="180px"
              content-class="bg-dark"
            >
              {{ product.title }}
            </q-tooltip>
          </q-item-label>
        </router-link>
        <q-item-label caption>
          <q-item-label class="col-6 product-qty">
            {{ product.price | currency }}
            <span> </span>
          </q-item-label>
          <AddToWishlist :products="product" />
          <AddToFavorite :products="product" />
          <q-item-section side>
            <AddToCartBtn
              :productFulFillments="
                product.fulFillments
                  ? product.fulFillments
                  : product.productFulfillments
              "
              :productId="product.productID"
              :isinstock="product.isinstock"
            />
          </q-item-section>
        </q-item-label>
      </q-item-section>
    </q-item>
  </q-card>
</template>

<script>
import { mapGetters } from 'vuex'
import AddToCartBtn from './AddToCartBtn'
import AddToFavorite from './AddToFavorite'
import AddToWishlist from './AddToWishlist'

export default {
  components: {
    AddToCartBtn,
    AddToFavorite,
    AddToWishlist,
  },
  props: {
    product: {
      type: Object,
      default: null,
    },
  },
}
</script>
